<template>
  <div>
    <el-divider class="divider-carts" content-position="left">Отказ</el-divider>
    <v-chart class="chart-e  h-550" :option="option" autoresize />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'top',
          padding: [
              10,  // up
              0, // right
              ,  // down
              0, // left
          ]
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: [
              { value: 40, name: 'rose 1' },
              { value: 40, name: 'rose 2' },
              { value: 32, name: 'rose 3' },
              { value: 30, name: 'rose 4' },
              { value: 28, name: 'rose 5' },
              { value: 26, name: 'rose 6' },
              { value: 22, name: 'rose 7' },
              { value: 18, name: 'rose 8' }
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>
<style>
.h-550{
  height: 650px !important;
}
</style>

