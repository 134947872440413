<template >
 <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                <router-link  class="mr-5" tag="a" to="/vacancy" >
                  <el-button icon="el-icon-back">Назад</el-button>
                </router-link>
                <span>HR menedjer</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="p-3 my-khan-tabs-top">
        <el-tabs v-model="activeName">
          <el-tab-pane name="info">
              <span slot="label" :class="mode ? 'text__day2' : 'text__night2'"><i class="el-icon-date"></i> Вакансии</span>
              <el-row :gutter="20" :class="mode ? 'text__day2' : 'text__night2'">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" >
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.nameGiven") }}:</strong
                    >
                    <span>{{ vacancy.name }}</span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.genders">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.gender") }}:</strong
                    >
                    <span
                      v-for="(gender, index) in vacancy.genders"
                      :key="'gender-' + index"
                    >
                      {{ gender.name }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" >
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.age") }}:</strong
                    >
                    <span>
                      {{ vacancy.min_age + "-" + vacancy.max_age }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" >
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.department") }}:</strong
                    >
                    <span>
                      {{ vacancy.branch ? vacancy.branch.name : null }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.departments">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.branch") }}:</strong
                    >
                    <span
                      v-for="(department, index) in vacancy.departments"
                      :key="'index' + index"
                    >
                      {{ department.name }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.priority">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.priority") }}:</strong
                    >
                    <span>
                      {{ vacancy.priority ? vacancy.priority.name : null }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.position">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.position") }}:</strong
                    >
                    <span>
                      {{ vacancy.position ? vacancy.position.name : null }}
                    </span>
                  </p>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.graphics">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.graphic") }}:</strong
                    >
                    <span
                      v-for="(graphic, index) in vacancy.graphics"
                      :key="'index' + index"
                    >
                      {{ graphic.name }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.languages">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.language") }}:</strong
                    >
                    <span
                      v-for="(language, index) in vacancy.languages"
                      :key="'index' + index"
                    >
                      {{ language.name }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.min_wage || vacancy.max_wage">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.salary") }}:</strong
                    >
                    <span>
                      {{ vacancy.min_wage + " - " + vacancy.max_wage }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.skills">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.skill") }}:</strong
                    >
                    <span
                      v-for="(skill, index) in vacancy.skills"
                      :key="'index' + index"
                    >
                      {{ skill.skill }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.region">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.region_") }}:</strong
                    >
                    <span>
                      {{ vacancy.region.name }}
                    </span>
                  </p>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"  v-if="vacancy.description">
                  <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                    <strong class="mr-1 font-bold mr-2"
                      >{{ $t("message.description") }}:</strong
                    >
                    <span>
                      {{ vacancy.description }}
                    </span>
                  </p>
                </el-col>
              </el-row>
          </el-tab-pane>
          <el-tab-pane  label="Config" name="second">
            <span slot="label" :class="mode ? 'text__day2' : 'text__night2'"><i class="el-icon-date"></i> Chart</span>
            <!-- <el-row class="button-filter text-right">
              <el-button
                  size="medium"
                  :class="[activeType == 'daily' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                  @click="handleClick('daily')"
                  >
                  {{$t("message.daily")}}
              </el-button>
              <el-button
                  size="medium"
                  :class="[activeType == 'weekly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                  @click="handleClick('weekly')"
                  > {{$t("message.weekly")}}
              </el-button>
              <el-button
                  size="medium"
                  :class="[activeType == 'monthly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                  @click="handleClick('monthly')"
                  >{{$t("message.monthly")}}
              </el-button>
              <el-button
                  size="medium"
                  :class="[activeType == 'yearly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                  @click="handleClick('yearly')"
                  >{{$t("message.yearly")}}
              </el-button>
            </el-row> -->
            <div style="width: 100%" v-if="social_chart.length>0">
                <chartSource :social_chart="social_chart" ></chartSource>
            </div>
            <!-- end Источник -->

            <div style="width: 100%">
                <chartStatus></chartStatus>
            </div>
            <!-- end Статус -->

            <div style="width: 100%" v-if="step_chart_count.length>0">
                <chartFunnel :step_chart_count="step_chart_count"></chartFunnel>
            </div>
            <!-- end Воронка -->

            <div style="width: 100%" v-if="step_chart.length>0">
                <chartFunnel2 :step_chart="step_chart"></chartFunnel2>
            </div>
            <!-- end Воронка2 -->

            <div style="width: 100%">
                <chartRefusal></chartRefusal>
            </div>
            <!-- end Отказ -->

            <div style="width: 100%">
              <chartBilmadim></chartBilmadim>
            </div>
            <!-- end Oxirgacha yoz odamni qinamasdan-->

            <div style="width: 100%">
              <chartHurry></chartHurry>
            </div>
            <!-- end Скорись -->


          </el-tab-pane>
          <el-tab-pane  name="third">
            <span slot="label" :class="mode ? 'text__day2' : 'text__night2'"><i class="el-icon-date"></i> Кандидаты</span>
            <div class=".dragn-item  p-3">
              <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"
                  v-for="(candidates, index) in list" :key="'candidates'+index">
                    <div class="dragn-item" :class="index">
                      <el-badge :value="candidates.length"  class="dragn-item-header">
                            <h3 class="dragn-item-title">{{ $t('message.candidate_'+index) }}</h3>
                        </el-badge>
                      <div class="dragn-item-main">
                        <draggable class="list-group" :list="candidates" group="people" @change="log($event, index)">
                          <div class="timeline-items__right user rounded-sm w-100"  v-for="(candidate) in candidates"
                          :key="candidate.id+candidate.name"  :class="mode ? 'table__myday' : 'table__mynight'">
                            <div class="info-user-left3" :class="mode ? '' : 'performance__itemnight'">
                              <div class="candidate_point" @click="showInfoList(candidate.id)">{{candidate.point}}</div>
                              <div class="img">
                                <img
                                  :src="
                                    candidate.image
                                      ? baseUrl + candidate.image.path
                                      : '/img/avatar.jpeg'
                                  "
                                  alt=""
                                />
                              </div>
                              <div class="name-info-user-left">
                                <div class="name_u"  :class="mode ? 'text__day2' : 'text__night2'">
                                  <b>{{ $t('message.fio') }}: </b>{{ candidate.name + " " + candidate.last_name }}
                                </div>
                                <div class="position_u">
                                  <b>{{ $t('message.gender') }}: </b>{{ candidate.gender?candidate.gender.name:'' }}
                                </div>
                                <div class="position_u">
                                  <b>{{ $t('message.phone_number') }}: </b>{{ candidate.phone_number?candidate.phone_number:'' }}
                                </div>
                                <div class="position_u">
                                  <b>{{ $t('message.nation') }}: </b>{{ candidate.nation?candidate.nation.nationality:'' }}
                                </div>
                                <div class="position_u">
                                  <b>{{ $t('message.date_of_birth') }}: </b>{{ candidate.date_of_birth }}
                                </div>

                              </div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-drawer
        title="I'm outer Drawer"
        :visible.sync="showInfoModal"
        size="40%"
        :with-header="false"
        ref="show-info"
        :append-to-body="true"
        @opened="drawerOpened('show-info')">
        <div>
          <show-info
            :candidate="selectedCandidate"
            @closeModal="closeModal"
            :child="child"
            ref="show-info"
          ></show-info>
        </div>
      </el-drawer>
  </div>
</template>
<script>
import CrmCreateCandidate from "../candidates/components/crm-create.vue";
import crmUpdateCandidate from "../candidates/components/crm-update.vue";
import selectGender from "@/components/filters/inventory/select-gender";
import selectStatus from "@/components/filters/inventory/select-status";
import CrmStaffCreateNew from "../candidates/components/crm-staff-create-new";
import showInfo from "../candidates/components/show-info.vue";
import drawer from "@/utils/mixins/drawer";
import draggable from "vuedraggable";
import { mapGetters, mapActions, mapState } from "vuex";

import chartSource from "./components/charts/chartSource.vue";
import chartStatus from "./components/charts/chartStatus.vue";
import chartFunnel from "./components/charts/chartFunnel.vue";
import chartFunnel2 from "./components/charts/chartFunnel2.vue";
import chartBilmadim from "./components/charts/chartBilmadim.vue";
import chartRefusal from "./components/charts/chartRefusal.vue";
import chartHurry from "./components/charts/chartHurry.vue";
export default {
  mixins: [drawer],
  name: "VacancyController",
  components: {
    CrmCreateCandidate,
    selectGender,
    selectStatus,
    crmUpdateCandidate,
    CrmStaffCreateNew,
    draggable,
    showInfo,
    chartSource,
    chartStatus,
    chartFunnel,
    chartFunnel2,
    chartBilmadim,
    chartRefusal,
    chartHurry
  },
  props: {

  },
  data() {
    return {
      activeType: "daily",
      activeName: 'info',
      showInfoModal: false,
      filterForm: {},
      loadingData: false,
      reloadList: false,
      loadingComments: false,
      drawerCreateStaff: false,
      selectedCandidate: null,
      child:{},
    };
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updatePagination({ key: "page", value: 1 });
        await this.updateFilter(newVal);
      },
      deep: true,
      immediate: true,
    },

    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  created() {
    this.fetchVacancy();
    this.fetchData();
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      list: "vacancy/vacancy_candidate_list",
      step_chart: "vacancy/step_chart",
      social_chart: "vacancy/social_chart",
      step_chart_count: "vacancy/step_chart_by_count",
      vacancy:"vacancy/model",
      columns: "candidate/columns",
      pagination: "vacancy/candidate_pagination",
      statues: "vacancy/statues",
      filter: "vacancy/filter",
      sort: "vacancy/sort",
      mode:'MODE'
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "vacancy/candidate",
      getSteppChart: "vacancy/getSteppChart",
      getSocialChart: "vacancy/getSocialChart",
      getSteppChartByCount: "vacancy/getSteppChartByCount",
      updateSort: "vacancy/updateSort",
      updateFilter: "vacancy/updateFilter",
      updateColumn: "vacancy/updateColumn",
      updatePagination: "vacancy/updateCandidatePagination",
      show: "vacancy/show",
      empty: "vacancy/empty",
      delete: "candidate/destroy",
      refreshData: "vacancy/refreshData",
      updateCandidate:"vacancy/updateCandidate"
    }),
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
    listChanged() {
      this.reloadList = true;
    },
    showInfoList(candidate_id) {
          this.showInfoModal = true;
          this.child.candidate_id = candidate_id;
    },
    closeModal(val) {
      this.showInfoModal = val;
    },
    fetchVacancy(){
      if (!this.loadProfile && this.$route.params.id) {
        this.loadProfile = true;
        this.show(this.$route.params.id)
          .then((res) => {
            this.loadProfile = false;
          })
          .catch((err) => {
            this.loadProfile = false;
          });
      }
    },
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (this.$route.params.id) {
        this.loadingData = true;
        this.updateList({ vacancy_id: this.$route.params.id, params: query })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
        this.getSocialChart(this.$route.params.id);
        this.getSteppChart(this.$route.params.id);
        this.getSteppChartByCount(this.$route.params.id);
      }
    },
    log: function(evt, index) {
      if(evt.added){
        let data = {
          vacancy_id: this.vacancy.id,
          candidate_id: evt.added.element.id,
          step_status:index
        }
        this.loadingData = true;

        this.updateCandidate(data)
        .then((res) => {

        })
        .catch((err) => {

        });
      }else if(evt.moved){

      }
      // window.console.log(evt);
    }
  },
};
</script>
<style lang="scss" >
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
.dragn-item{
    .timeline-items__right.user {
      margin-bottom: 15px;
      padding: 15px;
      .company_u{
        margin-bottom: 3px;
        font-size: 14px;
      }
      .info-user-left3 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        .candidate_point{
          position: absolute;
          width:30px;
          height: 30px;
          border-radius: 50%;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-weight: 600;
          color: #fff;
          line-height: 0;
          cursor: pointer;
        }
        .img {
          margin-right: 10px;
          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 10%;
          }
        }
      }
      .name_u {
        font-weight: bold;
        font-size: 13px;
      }
      .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
      }
      .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
      }
    }
    padding: 0;
    .dragn-item-header{
      display: block;
      width: 100%;
      padding: 15px 20px;
      background: #409eff;
      border-radius: 8px 8px 0px 0px;
      .dragn-item-title{
        margin: 0;
        color: #fff;
        font-size: 16px;
      }
    }
    .dragn-item-main{
      padding: 15px 18px;
      border: 2px solid #409eff;
      border-radius: 0px 0px 5px 5px;

    }
    &.new {
      .timeline-items__right{
        box-shadow: 0px 0px 5px -1px #409eff !important;
      }
      .dragn-item-header{
        background: #409eff;
      }
      .dragn-item-main{
        border: 2px solid #409eff;
      }
      .candidate_point{
        background: #409eff;
      }

    }
    &.called_interview{
      .timeline-items__right.user {
        box-shadow: 0px 0px 5px -1px #cf7f18 !important;
        border-color: #cf7f18;
      }
      .dragn-item-header{
        background: #cf7f18;
      }
      .dragn-item-main{
        border: 2px solid #cf7f18;
      }
      .candidate_point{
        background: #cf7f18;
      }
    }
    &.approved_employment{
      .timeline-items__right.user {
        box-shadow: 0px 0px 5px -1px #11a956 !important;
        border-color: #11a956;
      }
      .dragn-item-header{
        background: #11a956;
      }
      .dragn-item-main{
        border: 2px solid #11a956;
      }
      .candidate_point{
        background: #11a956;
      }
    }
}
.chart-e {
  width: 100%;
  height: 350px;
}
.divider-carts{
  margin: 40px 0 10px 0;
}
.tabble__night .my-khan-tabs-top{
  .el-divider__text {
    background-color: #100c2a;
    padding: 10px 20px;
    left: 0;
  }
  .el-divider {
    background-color: #100c2a;
  }
}
</style>
