<template>
  <div>
    <el-divider class="divider-carts" content-position="left">Oxirgacha yoz odamni qinamasdan </el-divider>
    <v-chart class="chart-e" :option="option" autoresize />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      option: {
        // backgroundColor: "transparent",
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },

        legend: {
          itemStyle: {
            // color: ['#FF0000'], // Set the desired background color for the legend square
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',]
        },
        series: [
          {
            name: 'Direct',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [820, 902, 701, 934, 890, 1030, 1020],
            itemStyle: {
              color: '#5470c6', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Mail Ad',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [620, 532, 601, 634, 790, 330, 820],
            itemStyle: {
              color: '#91cc75', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Video Ad',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [150, 212, 201, 154, 190, 330, 410],
            itemStyle: {
              color: '#73c0de', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Affiliate Ad',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              color: '#fac858', // Set the desired background color for the legend square
            }
          },

          {
            name: 'Search Engine',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [ 120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              color: 'crimson', // Set the desired background color for the legend square
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>


