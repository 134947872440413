<template>
  <div>
    <el-divider class="divider-carts" content-position="left">Воронка </el-divider>
    <v-chart class="chart-e" :option="option" :step_chart_count="step_chart_count" autoresize />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import { i18n } from '@/utils/i18n';

import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  props: {
    step_chart_count: {
      default: null,
    },
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      option: {
        // backgroundColor: "transparent",
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          itemStyle: {
            // color: ['#FF0000'], // Set the desired background color for the legend square
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['Воронка']
        },
        series: [
          {
            name: 'Этап 1',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [100],
            itemStyle: {
              color: '#5470c6', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Этап 2',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [79],
            itemStyle: {
              color: '#91cc75', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Этап 3',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [55],
            itemStyle: {
              color: '#73c0de', // Set the desired background color for the legend square
            }
          },
          
        ]
      }
    }
  },
  mounted(){
    console.log(this.step_chart_count)
    for (const key in this.step_chart_count) {
                  if (
                    this.step_chart_count.hasOwnProperty(
                      key
                        )
                    ) {
                        this.option.series[key].name = i18n.t('message.candidate_'+this.step_chart_count[key].name);
                        this.option.series[key].data[0] =this.step_chart_count[key].value;
                        };
                        
                    }
  },
  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>


